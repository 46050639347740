<template>
  <div class="wrapper" v-if="$route.query.lang !== 'en'" v-html="mdText">
  </div>
  <!-- <iframe v-else src="/info.html" width="100%" height="2000" frameborder="0"></iframe> -->
  <info-vue v-else></info-vue>
</template>

<script setup>
import { isZh } from 'app/i18n'
import md from '@/assets/md/zh/introduction.md'
import en_md from '@/assets/md/en/introduction.md'
import InfoVue from './info/index'
const mdText = isZh() ? md : en_md
</script>
<style lang="scss" scoped>
@import '@/styles/common.scss';
.wrapper {
  max-width: 1000px;
  margin: 0 auto;
}
</style>
