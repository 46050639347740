<template>
  <div class="wrapper-intro">
    <img src="./image/1.png" alt="">
    <img src="./image/2.png" alt="">
    <img src="./image/3.png" alt="">
    <img src="./image/4.png" alt="">
    <img src="./image/5.png" alt="">
    <img src="./image/6.png" alt="">
    <img src="./image/7.png" alt="">
    <img src="./image/8.png" alt="">
    <img src="./image/9.png" alt="">
    <img src="./image/10.png" alt="">
  </div>
</template>

<script setup>

</script>
<style lang="scss" scoped>
.wrapper-intro {
  max-width: 1000px;
  margin: 0 auto;
  padding: 87px 83px 24px;
}
</style>
